import React from "react";
import { SEO, PageLayout, HeroElement } from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class InThePressPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"In The Press"}
              tagline={""}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={"https://tinyurbankitchen.com/trattoria-di-monica/"}
                >
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicastrattoria/Tiny_Urban_Kitchen.jpeg"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={"https://tinyurbankitchen.com/trattoria-di-monica/"}
                  >
                    Trattoria di Monica
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.theinfatuation.com/boston/guides/where-to-eat-and-drink-in-the-north-end"
                  }
                >
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicastrattoria/Infatuation.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.theinfatuation.com/boston/guides/where-to-eat-and-drink-in-the-north-end"
                    }
                  >
                    Where To Eat And Drink In The North End
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://bostonmanmagazine.com/where-we-eat-monicas-trattoria/"
                  }
                >
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicastrattoria/Boston_Man.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://bostonmanmagazine.com/where-we-eat-monicas-trattoria/"
                    }
                  >
                    Where We Eat | Monica’s Trattoria
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.americaninno.com/boston/jimmy-fallon-dines-in-bostons-north-end/"
                  }
                >
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicastrattoria/Bostinno.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.americaninno.com/boston/jimmy-fallon-dines-in-bostons-north-end/"
                    }
                  >
                    Where Did Jimmy Fallon Dine in Boston this Past Weekend?
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.nbcsports.com/boston/bruins/julian-edelman-has-dinner-david-pastrnak-sean-kuraly-charlie-mcavoy"
                  }
                >
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicastrattoria/Sports_Boston.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.nbcsports.com/boston/bruins/julian-edelman-has-dinner-david-pastrnak-sean-kuraly-charlie-mcavoy"
                    }
                  />
                </h3>
                <div className={"sep"} />
              </div>
              <div className={"article-container"}>
                <a
                  target={"_blank"}
                  href={
                    "https://www.travelandleisure.com/trip-ideas/best-italian-restaurants-bostons-north-end"
                  }
                >
                  <Image
                    size={"medium"}
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/monicastrattoria/Travel_and_Leisure.png"
                    }
                  />
                </a>
                <h3>
                  <a
                    target={"_blank"}
                    href={
                      "https://www.travelandleisure.com/trip-ideas/best-italian-restaurants-bostons-north-end"
                    }
                  >
                    Best Italian Restaurants in Boston's North End
                  </a>
                </h3>
                <div className={"sep"} />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "In The Press" }) {
      title
    }
  }
`;
